import Layout from 'views/layout/Layout'

export default {
  path: '/tas',
  redirect: '/tas/bgms',
  name: 'tas',
  component: Layout,
  title: '训练产品',
  permission: ['tas'],
  hidden: false,
  icon: 'grouptraining',
  children: [
    {
      path: 'workouts',
      component: () => import('views/tas/workouts/Index'),
      title: '训练产品',
      keepAlive: true,
      name: 'WorkoutIndex',
      permission: ['tas', 'workouts'],
      hidden: false,
      children: [
        {
          path: ':workoutId',
          // redirect: '/workouts/:workoutId/packages',
          component: () => import('views/tas/workouts/Detail'),
          name: 'workoutDetail',
          // keepAlive: true,
          title: '产品详情',
          root: true,
        },
        {
          path: 'workout_versions/:workoutVersionId/template_outline',
          name: 'templateOutline',
          component: () => import('views/tas/workouts/workout_versions/TemplateOutline'),
          title: '课程结构',
          keepAlive: true,
          root: true,
        }
      ]
    },
    {
      path: 'workout_outlines',
      // component: () => import('views/workout_outline/Detail'),
      redirect: ':workoutOutlineId',
      name: 'workout_outlineDetail',
      title: '课程内容详情',
      children: [{
        path: ':workoutOutlineId',
        component: () => import('views/tas/workout_outlines/Detail'),
        name: 'workoutOutlineDetail',
        title: '课程内容详情',
        root: true,
        children: [{
            path: 'outline',
            component: () => import('views/tas/workout_outlines/outline/Outline'),
            name: 'workoutOutlineDetailOutline',
            title: '课程内容',
          },
          // {
          //   path: 'timeline',
          //   component: () => import('views/tas/workout_outlines/timeline/Timeline'),
          //   name: 'workoutOutlineDetailTimeline',
          //   title: '课程时间线',
          // },
          // {
          //   path: 'default_playlist',
          //   component: () => import('views/tas/workout_outlines/default_playlist/DefaultPlaylist'),
          //   name: 'workoutOutlineDetailDefaultPlaylist',
          //   title: '课程默认歌单',
          // },
        ],
      }],
    },
    {
      path: 'bgms',
      component: () => import('views/tas/bgms/Index'),
      title: '音乐库',
      keepAlive: true,
      name: 'BgmIndex',
      permission: ['tas', 'bgms'],
      hidden: false,
    },
    {
      path: 'exercises',
      component: () => import('views/tas/exercises/Index'),
      title: '动作库',
      keepAlive: true,
      name: 'ExerciseIndex',
      permission: ['tas', 'exercises'],
      hidden: false,
    },
  ],
}
