import Layout from 'views/layout/Layout'

export default {
  path: '/price_inqueries',
  redirect: '/price_inqueries/list',
  component: Layout,
  name: 'PriceInquiry',
  title: '报价咨询',
  hidden: false,
  icon: 'store',
  permission: ['price_inquiry'],
  children: [
    {
      path: 'list',
      component: () => import('views/price_inquiry/Index'),
      name: 'PriceInquiryList',
      title: '报价咨询',
      keepAlive: true,
      permission: ['price_inquiry'],
      hidden: false
    },
  ]
}