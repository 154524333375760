import request from "@/utils/request-future"

export const API = {
  /**
   * @type {(params: {active?: boolean}) => Promise<import('./data').default['AdminPagedArrayDefault']>}
   */
  adminAdminIndex: (params) => {
    return request(`/admin/admins`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["AdminForm"]) => Promise<import('./data').default['any']>}
   */
  adminAdminCreate: (data) => {
    return request(`/admin/admins`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['AdminDefault']>}
   */
  adminAdminShow: (id) => {
    return request(`/admin/admins/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["AdminForm"]) => Promise<import('./data').default['any']>}
   */
  adminAdminUpdate: (id, data) => {
    return request(`/admin/admins/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  adminAdminDestroy: (id) => {
    return request(`/admin/admins/${id}`, { method: "DELETE" })
  },

  /**
   * @type {() => Promise<import('./data').default['PermissionRolePagedArrayList']>}
   */
  adminRoleList: () => {
    return request(`/admin/roles/list`, { method: "GET" })
  },

  /**
   * @type {() => Promise<import('./data').default['PermissionRolePagedArrayDefault']>}
   */
  adminRoleIndex: () => {
    return request(`/admin/roles`, { method: "GET" })
  },

  /**
   * @type {(data?: definitions["PermissionRoleForm"]) => Promise<import('./data').default['any']>}
   */
  adminRoleCreate: (data) => {
    return request(`/admin/roles`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['PermissionRoleDefault']>}
   */
  adminRoleShow: (id) => {
    return request(`/admin/roles/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["PermissionRoleForm"]) => Promise<import('./data').default['any']>}
   */
  adminRoleUpdate: (id, data) => {
    return request(`/admin/roles/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  adminRoleDestroy: (id) => {
    return request(`/admin/roles/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(signedId: string) => Promise<import('./data').default['AttachmentDefault']>}
   */
  attachmentShow: (signedId) => {
    return request(`/attachments/${signedId}`, { method: "GET" })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['BrandBrandList'][]>}
   */
  brandBrandList: (params) => {
    return request(`/brand/brands/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['PlatformBrandBrandDefault']>}
   */
  brandBrandIndex: (params) => {
    return request(`/brand/brands`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["PlatformBrandBrandForm"]) => Promise<import('./data').default['any']>}
   */
  brandBrandCreate: (data) => {
    return request(`/brand/brands`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['PlatformBrandBrandDefault']>}
   */
  brandBrandShow: (id) => {
    return request(`/brand/brands/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["PlatformBrandBrandForm"]) => Promise<import('./data').default['any']>}
   */
  brandBrandUpdate: (id, data) => {
    return request(`/brand/brands/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  brandBrandDestroy: (id) => {
    return request(`/brand/brands/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(bradndId: number) => Promise<import('./data').default['any']>}
   */
  brandBrandResetAdminPassword: (bradndId) => {
    return request(`/brand/brands/${bradndId}/reset_admin_password`, {
      method: "PATCH",
    })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  brandBrandOperatingTags: () => {
    return request(`/brand/brands/operating_tags`, { method: "GET" })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['BrandMpConfigList'][]>}
   */
  brandMpConfigList: (params) => {
    return request(`/brand/mp_configs/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['BrandMpConfigPagedArrayDefault']>}
   */
  brandMpConfigIndex: (params) => {
    return request(`/brand/mp_configs`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["BrandMpConfigForm"]) => Promise<import('./data').default['any']>}
   */
  brandMpConfigCreate: (data) => {
    return request(`/brand/mp_configs`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['BrandMpConfigDetail']>}
   */
  brandMpConfigShow: (id) => {
    return request(`/brand/mp_configs/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["BrandMpConfigForm"]) => Promise<import('./data').default['any']>}
   */
  brandMpConfigUpdate: (id, data) => {
    return request(`/brand/mp_configs/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  brandMpConfigDestroy: (id) => {
    return request(`/brand/mp_configs/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['BrandPayConfigDefault'][]>}
   */
  brandPayConfigList: (params) => {
    return request(`/brand/pay_configs/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['BrandPayConfigPagedArrayDefault']>}
   */
  brandPayConfigIndex: (params) => {
    return request(`/brand/pay_configs`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["BrandPayConfigForm"]) => Promise<import('./data').default['any']>}
   */
  brandPayConfigCreate: (data) => {
    return request(`/brand/pay_configs`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['BrandPayConfigDetail']>}
   */
  brandPayConfigShow: (id) => {
    return request(`/brand/pay_configs/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["BrandPayConfigForm"]) => Promise<import('./data').default['any']>}
   */
  brandPayConfigUpdate: (id, data) => {
    return request(`/brand/pay_configs/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  brandPayConfigDestroy: (id) => {
    return request(`/brand/pay_configs/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(data: any) => Promise<import('./data').default['any']>}
   */
  brandPayConfigUploadCert: (data) => {
    return request(`/brand/pay_configs/${id}/cert`, { method: "POST", data })
  },

  /**
   * @type {() => Promise<import('./data').default['PermissionRolePagedArrayList']>}
   */
  brandRoleList: () => {
    return request(`/brand/roles/list`, { method: "GET" })
  },

  /**
   * @type {() => Promise<import('./data').default['PermissionRolePagedArrayList']>}
   */
  brandRoleIndex: () => {
    return request(`/brand/roles`, { method: "GET" })
  },

  /**
   * @type {(data?: definitions["PermissionRoleForm"]) => Promise<import('./data').default['any']>}
   */
  brandRoleCreate: (data) => {
    return request(`/brand/roles`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['PermissionRoleDefault']>}
   */
  brandRoleShow: (id) => {
    return request(`/brand/roles/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["PermissionRoleForm"]) => Promise<import('./data').default['any']>}
   */
  brandRoleUpdate: (id, data) => {
    return request(`/brand/roles/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  brandRoleDestroy: (id) => {
    return request(`/brand/roles/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['BrandThemeDefault'][]>}
   */
  brandThemeList: (params) => {
    return request(`/brand/themes/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['BrandThemePagedArrayDefault']>}
   */
  brandThemeIndex: (params) => {
    return request(`/brand/themes`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["BrandThemeForm"]) => Promise<import('./data').default['any']>}
   */
  brandThemeCreate: (data) => {
    return request(`/brand/themes`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['BrandThemeDefault']>}
   */
  brandThemeShow: (id) => {
    return request(`/brand/themes/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["BrandThemeForm"]) => Promise<import('./data').default['any']>}
   */
  brandThemeUpdate: (id, data) => {
    return request(`/brand/themes/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  brandThemeDestroy: (id) => {
    return request(`/brand/themes/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(brandId: number, params: {id?: number}) => Promise<import('./data').default['CoachCoachList'][]>}
   */
  coachCoachList: (brandId, params) => {
    return request(`/brand/brands/${brandId}/coaches/list`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(brandId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['CoachCoachPagedArrayWithCoachProducts']>}
   */
  coachCoachIndex: (brandId, params) => {
    return request(`/brand/brands/${brandId}/coaches`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(brandId: number, data?: definitions["CoachCoachCreateForm"]) => Promise<import('./data').default['any']>}
   */
  coachCoachCreate: (brandId, data) => {
    return request(`/brand/brands/${brandId}/coaches`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CoachCoachWithCoachProducts']>}
   */
  coachCoachShow: (id) => {
    return request(`/brand/coaches/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CoachCoachUpdateForm"]) => Promise<import('./data').default['any']>}
   */
  coachCoachUpdate: (id, data) => {
    return request(`/brand/coaches/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(brandId: number) => Promise<import('./data').default['CoachCoachWithCoachProducts']>}
   */
  coachCoachTags: (brandId) => {
    return request(`/brand/brands/${brandId}/coaches/tags`, { method: "GET" })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  coachCoachOperatingTags: () => {
    return request(`/coaches/operating_tags`, { method: "GET" })
  },

  /**
   * @type {(coachId: number, data?: definitions["CoachCoachProductGrantForm"]) => Promise<import('./data').default['any']>}
   */
  coachCoachGrantProducts: (coachId, data) => {
    return request(`/brand/coaches/${coachId}/grant_products`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(brandId: number, params: {coachId: number, usage?: string}) => Promise<import('./data').default['CourseProductCoachProductOperating'][]>}
   */
  coachProductIndex: (brandId, params) => {
    return request(`/brand/brands/${brandId}/coach_products`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(brandId: number, id: number) => Promise<import('./data').default['CoachCoachProductDefault']>}
   */
  coachProductShow: (brandId, id) => {
    return request(`/brand/brands/${brandId}/coach_products/${id}`, {
      method: "GET",
    })
  },

  /**
   * @type {(brandId: number, id: number, data?: definitions["CoachCoachProductForm"]) => Promise<import('./data').default['any']>}
   */
  coachProductUpdate: (brandId, id, data) => {
    return request(`/brand/brands/${brandId}/coach_products/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(brandId: number, id: number) => Promise<import('./data').default['any']>}
   */
  coachProductDestroy: (brandId, id) => {
    return request(`/brand/brands/${brandId}/coach_products/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(brandId: number, params: {page?: number, pageSize?: number, coachId: number, usage?: string, active?: boolean}) => Promise<import('./data').default['CourseProductList'][]>}
   */
  courseProductList: (brandId, params) => {
    return request(`/brand/brands/${brandId}/products/list`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(brandId: number, params: {active?: boolean, page?: number, pageSize?: number}) => Promise<import('./data').default['PlatformCourseProductPagedArrayDefault']>}
   */
  courseProductIndex: (brandId, params) => {
    return request(`/brand/brands/${brandId}/products`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(brandId: any, data?: definitions["CourseProductPlatformForm"]) => Promise<import('./data').default['any']>}
   */
  courseProductCreate: (brandId, data) => {
    return request(`/brand/brands/${brandId}/products`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(brandId: number, id: number) => Promise<import('./data').default['CourseProductDetail']>}
   */
  courseProductShow: (brandId, id) => {
    return request(`/brand/brands/${brandId}/products/${id}`, { method: "GET" })
  },

  /**
   * @type {(brandId: number, id: number, data?: definitions["CourseProductPlatformForm"]) => Promise<import('./data').default['any']>}
   */
  courseProductUpdate: (brandId, id, data) => {
    return request(`/brand/brands/${brandId}/products/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(brandId: any, data?: definitions["BrandBrandGrantProductsForm"]) => Promise<import('./data').default['any']>}
   */
  courseProductGrant: (brandId, data) => {
    return request(`/brand/brands/${brandId}/products/grant`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(brandId: any, data?: definitions["BrandBrandGrantProductsForm"]) => Promise<import('./data').default['any']>}
   */
  courseProductKidGrant: (brandId, data) => {
    return request(`/brand/brands/${brandId}/products/kid_grant`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['KtasCharacterTrainingDefault'][]>}
   */
  ktasCharacterTrainingList: (params) => {
    return request(`/ktas/character_trainings/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {active?: boolean, page?: number, pageSize?: number}) => Promise<import('./data').default['KtasCharacterTrainingPagedArrayDefault']>}
   */
  ktasCharacterTrainingIndex: (params) => {
    return request(`/ktas/character_trainings`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["KtasCharacterTrainingForm"]) => Promise<import('./data').default['any']>}
   */
  ktasCharacterTrainingCreate: (data) => {
    return request(`/ktas/character_trainings`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasCharacterTrainingDefault']>}
   */
  ktasCharacterTrainingShow: (id) => {
    return request(`/ktas/character_trainings/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasCharacterTrainingForm"]) => Promise<import('./data').default['any']>}
   */
  ktasCharacterTrainingUpdate: (id, data) => {
    return request(`/ktas/character_trainings/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasCharacterTrainingDestroy: (id) => {
    return request(`/ktas/character_trainings/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['KtasGameDefault'][]>}
   */
  ktasGameList: (params) => {
    return request(`/ktas/games/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {tagName: string}) => Promise<import('./data').default['any']>}
   */
  ktasGameTags: (params) => {
    return request(`/ktas/games/tags`, { method: "GET", params })
  },

  /**
   * @type {(params: {query?: string, active?: boolean, page?: number, pageSize?: number}) => Promise<import('./data').default['KtasGamePagedArrayDefault']>}
   */
  ktasGameIndex: (params) => {
    return request(`/ktas/games`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["KtasGameForm"]) => Promise<import('./data').default['any']>}
   */
  ktasGameCreate: (data) => {
    return request(`/ktas/games`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasGameDefault']>}
   */
  ktasGameShow: (id) => {
    return request(`/ktas/games/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasGameForm"]) => Promise<import('./data').default['any']>}
   */
  ktasGameUpdate: (id, data) => {
    return request(`/ktas/games/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasGameDestroy: (id) => {
    return request(`/ktas/games/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(workoutId: number, params: {id?: number}) => Promise<import('./data').default['KtasOutlineDefault'][]>}
   */
  ktasOutlineList: (workoutId, params) => {
    return request(`/ktas/workouts/${workoutId}/outlines/list`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {tagName: string}) => Promise<import('./data').default['any']>}
   */
  ktasOutlineTags: (params) => {
    return request(`/ktas/outlines/tags`, { method: "GET", params })
  },

  /**
   * @type {(workoutId: number, params: {state?: string, page?: number, pageSize?: number}) => Promise<import('./data').default['KtasOutlinePagedArrayDefault']>}
   */
  ktasOutlineIndex: (workoutId, params) => {
    return request(`/ktas/workouts/${workoutId}/outlines`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(workoutId: number, data?: definitions["KtasOutlineForm"]) => Promise<import('./data').default['any']>}
   */
  ktasOutlineCreate: (workoutId, data) => {
    return request(`/ktas/workouts/${workoutId}/outlines`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasOutlineDetail']>}
   */
  ktasOutlineShow: (id) => {
    return request(`/ktas/outlines/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasOutlineForm"]) => Promise<import('./data').default['any']>}
   */
  ktasOutlineUpdate: (id, data) => {
    return request(`/ktas/outlines/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasOutlineDestroy: (id) => {
    return request(`/ktas/outlines/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasOutlineCertificate: (id) => {
    return request(`/ktas/outlines/${id}/certificate`, { method: "PUT" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasOutlineAbandon: (id) => {
    return request(`/ktas/outlines/${id}/abandon`, { method: "PUT" })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['KtasPerceptionTrainingDefault'][]>}
   */
  ktasPerceptionTrainingList: (params) => {
    return request(`/ktas/perception_trainings/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {active?: boolean, page?: number, pageSize?: number}) => Promise<import('./data').default['KtasPerceptionTrainingPagedArrayDefault']>}
   */
  ktasPerceptionTrainingIndex: (params) => {
    return request(`/ktas/perception_trainings`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["KtasPerceptionTrainingForm"]) => Promise<import('./data').default['any']>}
   */
  ktasPerceptionTrainingCreate: (data) => {
    return request(`/ktas/perception_trainings`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasPerceptionTrainingDefault']>}
   */
  ktasPerceptionTrainingShow: (id) => {
    return request(`/ktas/perception_trainings/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasPerceptionTrainingForm"]) => Promise<import('./data').default['any']>}
   */
  ktasPerceptionTrainingUpdate: (id, data) => {
    return request(`/ktas/perception_trainings/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasPerceptionTrainingDestroy: (id) => {
    return request(`/ktas/perception_trainings/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {tagName: string}) => Promise<import('./data').default['any']>}
   */
  ktasSectionTags: (params) => {
    return request(`/ktas/sections/tags`, { method: "GET", params })
  },

  /**
   * @type {(outlineId: number, params: {active?: boolean, page?: number, pageSize?: number}) => Promise<import('./data').default['KtasSectionPagedArrayDefault']>}
   */
  ktasSectionIndex: (outlineId, params) => {
    return request(`/ktas/outlines/${outlineId}/sections`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(outlineId: number, data?: definitions["KtasSectionForm"]) => Promise<import('./data').default['any']>}
   */
  ktasSectionCreate: (outlineId, data) => {
    return request(`/ktas/outlines/${outlineId}/sections`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasSectionDefault']>}
   */
  ktasSectionShow: (id) => {
    return request(`/ktas/sections/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasSectionForm"]) => Promise<import('./data').default['any']>}
   */
  ktasSectionUpdate: (id, data) => {
    return request(`/ktas/sections/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasSectionDestroy: (id) => {
    return request(`/ktas/sections/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['KtasStageDefault'][]>}
   */
  ktasStageList: (params) => {
    return request(`/ktas/stages/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {active?: boolean, page?: number, pageSize?: number}) => Promise<import('./data').default['KtasStagePagedArrayDefault']>}
   */
  ktasStageIndex: (params) => {
    return request(`/ktas/stages`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["KtasStageForm"]) => Promise<import('./data').default['any']>}
   */
  ktasStageCreate: (data) => {
    return request(`/ktas/stages`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasStageDefault']>}
   */
  ktasStageShow: (id) => {
    return request(`/ktas/stages/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasStageForm"]) => Promise<import('./data').default['any']>}
   */
  ktasStageUpdate: (id, data) => {
    return request(`/ktas/stages/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasStageDestroy: (id) => {
    return request(`/ktas/stages/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['KtasTrainingMethodDefault'][]>}
   */
  ktasTrainingMethodList: (params) => {
    return request(`/ktas/training_methods/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {tagName: string}) => Promise<import('./data').default['any']>}
   */
  ktasTrainingMethodTags: (params) => {
    return request(`/ktas/training_methods/tags`, { method: "GET", params })
  },

  /**
   * @type {(params: {query?: string, active?: boolean, page?: number, pageSize?: number}) => Promise<import('./data').default['KtasTrainingMethodPagedArrayDefault']>}
   */
  ktasTrainingMethodIndex: (params) => {
    return request(`/ktas/training_methods`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["KtasTrainingMethodForm"]) => Promise<import('./data').default['any']>}
   */
  ktasTrainingMethodCreate: (data) => {
    return request(`/ktas/training_methods`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasTrainingMethodDefault']>}
   */
  ktasTrainingMethodShow: (id) => {
    return request(`/ktas/training_methods/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasTrainingMethodForm"]) => Promise<import('./data').default['any']>}
   */
  ktasTrainingMethodUpdate: (id, data) => {
    return request(`/ktas/training_methods/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasTrainingMethodDestroy: (id) => {
    return request(`/ktas/training_methods/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['KtasWorkoutDefault'][]>}
   */
  ktasWorkoutList: (params) => {
    return request(`/ktas/workouts/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {active?: boolean, page?: number, pageSize?: number}) => Promise<import('./data').default['KtasWorkoutPagedArrayDefault']>}
   */
  ktasWorkoutIndex: (params) => {
    return request(`/ktas/workouts`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["KtasWorkoutForm"]) => Promise<import('./data').default['any']>}
   */
  ktasWorkoutCreate: (data) => {
    return request(`/ktas/workouts`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasWorkoutDefault']>}
   */
  ktasWorkoutShow: (id) => {
    return request(`/ktas/workouts/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasWorkoutForm"]) => Promise<import('./data').default['any']>}
   */
  ktasWorkoutUpdate: (id, data) => {
    return request(`/ktas/workouts/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasWorkoutDestroy: (id) => {
    return request(`/ktas/workouts/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {tagName: string}) => Promise<import('./data').default['any']>}
   */
  ktasWorkoutTags: (params) => {
    return request(`/ktas/workouts/tags`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, usage?: string, state?: string}) => Promise<import('./data').default['BrandBrandPagedArrayOperating']>}
   */
  operatingProductCourseProducts: (params) => {
    return request(`/operating/course_products`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, state?: string}) => Promise<import('./data').default['CoachCoachPagedArrayOperating']>}
   */
  operatingProductCoachProducts: (params) => {
    return request(`/operating/coach_products`, { method: "GET", params })
  },

  /**
   * @type {(params: {featureType?: string}) => Promise<import('./data').default['PermissionFeatureIncludeChildren'][]>}
   */
  permissionFeatureIndex: (params) => {
    return request(`/permission/features`, { method: "GET", params })
  },

  /**
   * @type {() => Promise<import('./data').default['AdminWithGrantedFeatures']>}
   */
  generalCurrentAdmin: () => {
    return request(`/current_admin`, { method: "GET" })
  },

  /**
   * @type {(params: {processed?: boolean, page?: number, pageSize?: number}) => Promise<import('./data').default['PriceInquiryPagedArrayDefault']>}
   */
  priceInquiryIndex: (params) => {
    return request(`/price_inquiries`, { method: "GET", params })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  priceInquiryProcess: (id) => {
    return request(`/price_inquiries/${id}/process`, { method: "PUT" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  priceInquiryDestroy: (id) => {
    return request(`/price_inquiries/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(brandId: number, studioId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['StudioBlockPagedArrayDefault']>}
   */
  studioBlockIndex: (brandId, studioId, params) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/blocks`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(brandId: number, studioId: number, data?: definitions["StudioBlockForm"]) => Promise<import('./data').default['any']>}
   */
  studioBlockCreate: (brandId, studioId, data) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/blocks`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(brandId: number, studioId: number, id: number) => Promise<import('./data').default['StudioBlockDefault']>}
   */
  studioBlockShow: (brandId, studioId, id) => {
    return request(
      `/brand/brands/${brandId}/studios/${studioId}/blocks/${id}`,
      { method: "GET" }
    )
  },

  /**
   * @type {(brandId: number, studioId: number, id: number, data?: definitions["StudioBlockForm"]) => Promise<import('./data').default['any']>}
   */
  studioBlockUpdate: (brandId, studioId, id, data) => {
    return request(
      `/brand/brands/${brandId}/studios/${studioId}/blocks/${id}`,
      { method: "PUT", data }
    )
  },

  /**
   * @type {(brandId: number, studioId: number, id: number) => Promise<import('./data').default['any']>}
   */
  studioBlockDestroy: (brandId, studioId, id) => {
    return request(
      `/brand/brands/${brandId}/studios/${studioId}/blocks/${id}`,
      { method: "DELETE" }
    )
  },

  /**
   * @type {(brandId: number, studioId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['StudioHrSensorPagedArrayDefault']>}
   */
  studioHrSensorIndex: (brandId, studioId, params) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/hr_sensors`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(brandId: number, studioId: number, data?: definitions["StudioHrSensorForm"]) => Promise<import('./data').default['any']>}
   */
  studioHrSensorCreate: (brandId, studioId, data) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/hr_sensors`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(brandId: number, studioId: number, id: number) => Promise<import('./data').default['StudioHrSensorDefault']>}
   */
  studioHrSensorShow: (brandId, studioId, id) => {
    return request(
      `/brand/brands/${brandId}/studios/${studioId}/hr_sensors/${id}`,
      { method: "GET" }
    )
  },

  /**
   * @type {(brandId: number, studioId: number, id: number, data?: definitions["StudioHrSensorForm"]) => Promise<import('./data').default['any']>}
   */
  studioHrSensorUpdate: (brandId, studioId, id, data) => {
    return request(
      `/brand/brands/${brandId}/studios/${studioId}/hr_sensors/${id}`,
      { method: "PUT", data }
    )
  },

  /**
   * @type {(brandId: number, studioId: number, id: number) => Promise<import('./data').default['any']>}
   */
  studioHrSensorDestroy: (brandId, studioId, id) => {
    return request(
      `/brand/brands/${brandId}/studios/${studioId}/hr_sensors/${id}`,
      { method: "DELETE" }
    )
  },

  /**
   * @type {(brandId: number, studioId: number) => Promise<import('./data').default['AttachmentPagedArrayDefault']>}
   */
  studioImageIndex: (brandId, studioId) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/images`, {
      method: "GET",
    })
  },

  /**
   * @type {(brandId: number, studioId: number, data?: definitions["AttachmentForm"]) => Promise<import('./data').default['any']>}
   */
  studioImageCreate: (brandId, studioId, data) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/images`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(brandId: number, studioId: number, id: number) => Promise<import('./data').default['any']>}
   */
  studioImageDestroy: (brandId, studioId, id) => {
    return request(
      `/brand/brands/${brandId}/studios/${studioId}/images/${id}`,
      { method: "DELETE" }
    )
  },

  /**
   * @type {(brandId: number, studioId: number, id: number) => Promise<import('./data').default['any']>}
   */
  studioImagePrimary: (brandId, studioId, id) => {
    return request(
      `/brand/brands/${brandId}/studios/${studioId}/images/${id}/primary`,
      { method: "PUT" }
    )
  },

  /**
   * @type {(brandId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['StudioStudioPagedArrayDefault']>}
   */
  studioStudioIndex: (brandId, params) => {
    return request(`/brand/brands/${brandId}/studios`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(brandId: number, data?: definitions["StudioStudioForm"]) => Promise<import('./data').default['any']>}
   */
  studioStudioCreate: (brandId, data) => {
    return request(`/brand/brands/${brandId}/studios`, { method: "POST", data })
  },

  /**
   * @type {(brandId: number, id: number) => Promise<import('./data').default['StudioStudioDefault']>}
   */
  studioStudioShow: (brandId, id) => {
    return request(`/brand/brands/${brandId}/studios/${id}`, { method: "GET" })
  },

  /**
   * @type {(brandId: number, id: number, data?: definitions["StudioStudioForm"]) => Promise<import('./data').default['any']>}
   */
  studioStudioUpdate: (brandId, id, data) => {
    return request(`/brand/brands/${brandId}/studios/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(brandId: number, id: number) => Promise<import('./data').default['any']>}
   */
  studioStudioDestroy: (brandId, id) => {
    return request(`/brand/brands/${brandId}/studios/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(brandId: number, studioId: number, params: {id?: number}) => Promise<import('./data').default['StudioZoneList'][]>}
   */
  studioZoneList: (brandId, studioId, params) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/zones/list`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(brandId: number, studioId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['StudioZonePagedArrayDefault']>}
   */
  studioZoneIndex: (brandId, studioId, params) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/zones`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(brandId: number, studioId: number, data?: definitions["StudioZoneForm"]) => Promise<import('./data').default['any']>}
   */
  studioZoneCreate: (brandId, studioId, data) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/zones`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(brandId: number, studioId: number, id: number) => Promise<import('./data').default['StudioZoneDefault']>}
   */
  studioZoneShow: (brandId, studioId, id) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/zones/${id}`, {
      method: "GET",
    })
  },

  /**
   * @type {(brandId: number, studioId: number, id: number, data?: definitions["StudioZoneForm"]) => Promise<import('./data').default['any']>}
   */
  studioZoneUpdate: (brandId, studioId, id, data) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/zones/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(brandId: number, studioId: number, id: number) => Promise<import('./data').default['any']>}
   */
  studioZoneDestroy: (brandId, studioId, id) => {
    return request(`/brand/brands/${brandId}/studios/${studioId}/zones/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(params: {query?: string, category?: string, bgmTagList?: { [key: string]: any }, active?: boolean}) => Promise<import('./data').default['TasBgmPagedArrayDefault']>}
   */
  tasBgmIndex: (params) => {
    return request(`/tas/bgms`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["TasBgmForm"]) => Promise<import('./data').default['any']>}
   */
  tasBgmCreate: (data) => {
    return request(`/tas/bgms`, { method: "POST", data })
  },

  /**
   * @type {(params: {active?: boolean, query?: string, category?: string}) => Promise<import('./data').default['any']>}
   */
  tasBgmTags: (params) => {
    return request(`/tas/bgms/tags`, { method: "GET", params })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasBgmDefault']>}
   */
  tasBgmShow: (id) => {
    return request(`/tas/bgms/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasBgmForm"]) => Promise<import('./data').default['any']>}
   */
  tasBgmUpdate: (id, data) => {
    return request(`/tas/bgms/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasBgmDestroy: (id) => {
    return request(`/tas/bgms/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasBigCircleDetail']>}
   */
  tasBigCircleShow: (id) => {
    return request(`/tas/big_circles/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasBigCircleForm"]) => Promise<import('./data').default['any']>}
   */
  tasBigCircleUpdate: (id, data) => {
    return request(`/tas/big_circles/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasBigCircleDestroy: (id) => {
    return request(`/tas/big_circles/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(workoutOutlineId: number, data?: definitions["TasBigCircleForm"]) => Promise<import('./data').default['any']>}
   */
  tasBigCircleCreate: (workoutOutlineId, data) => {
    return request(`/tas/workout_outlines/${workoutOutlineId}/big_circles`, {
      method: "POST",
      data,
    })
  },

  /**
         * @type {(id: number, data?: {
"exercise_pattern"?: number;
"exercise_id"?: number;

}) => Promise<import('./data').default['any']>}
         */
  tasBigCircleUpdatePractices: (id, data) => {
    return request(`/tas/big_circles/${id}/update_practices`, {
      method: "PUT",
      data,
    })
  },

  /**
         * @type {(id: number, data?: {
"source_big_circle_id"?: number;

}) => Promise<import('./data').default['any']>}
         */
  tasBigCircleCopyExercises: (id, data) => {
    return request(`/tas/big_circles/${id}/copy_exercises`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['TasBlockTypeList'][]>}
   */
  tasBlockTypeList: (params) => {
    return request(`/tas/block_types/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['TasBlockTypePagedArrayDefault']>}
   */
  tasBlockTypeIndex: (params) => {
    return request(`/tas/block_types`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["TasBlockTypeForm"]) => Promise<import('./data').default['any']>}
   */
  tasBlockTypeCreate: (data) => {
    return request(`/tas/block_types`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasBlockTypeDetail']>}
   */
  tasBlockTypeShow: (id) => {
    return request(`/tas/block_types/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasBlockTypeForm"]) => Promise<import('./data').default['any']>}
   */
  tasBlockTypeUpdate: (id, data) => {
    return request(`/tas/block_types/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasBlockTypeDestroy: (id) => {
    return request(`/tas/block_types/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasContentImageDefault']>}
   */
  tasContentImageShow: (id) => {
    return request(`/tas/content_images/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasContentImageForm"]) => Promise<import('./data').default['any']>}
   */
  tasContentImageUpdate: (id, data) => {
    return request(`/tas/content_images/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasContentImageDestroy: (id) => {
    return request(`/tas/content_images/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(stepId: number, data?: definitions["TasContentImageForm"]) => Promise<import('./data').default['any']>}
   */
  tasContentImageCreate: (stepId, data) => {
    return request(`/tas/steps/${stepId}/content_images`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number, params: {position?: number}) => Promise<import('./data').default['any']>}
   */
  tasContentImageMoveTo: (id, params) => {
    return request(`/tas/content_images/${id}/move_to`, {
      method: "PUT",
      params,
    })
  },

  /**
   * @type {() => Promise<import('./data').default['TasEquipmentDefault'][]>}
   */
  tasEquipmentIndex: () => {
    return request(`/tas/equipment`, { method: "GET" })
  },

  /**
   * @type {(data?: definitions["TasEquipmentForm"]) => Promise<import('./data').default['any']>}
   */
  tasEquipmentCreate: (data) => {
    return request(`/tas/equipment`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasEquipmentDetail']>}
   */
  tasEquipmentShow: (id) => {
    return request(`/tas/equipment/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasEquipmentForm"]) => Promise<import('./data').default['any']>}
   */
  tasEquipmentUpdate: (id, data) => {
    return request(`/tas/equipment/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(params: {query?: string, primaryEquipmentId?: number, active?: boolean}) => Promise<import('./data').default['PlatformTasExercisePagedArrayDefault']>}
   */
  tasExerciseIndex: (params) => {
    return request(`/tas/exercises`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["PlatformTasExerciseForm"]) => Promise<import('./data').default['any']>}
   */
  tasExerciseCreate: (data) => {
    return request(`/tas/exercises`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['PlatformTasExerciseDetail']>}
   */
  tasExerciseShow: (id) => {
    return request(`/tas/exercises/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["PlatformTasExerciseForm"]) => Promise<import('./data').default['any']>}
   */
  tasExerciseUpdate: (id, data) => {
    return request(`/tas/exercises/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasExerciseDestroy: (id) => {
    return request(`/tas/exercises/${id}`, { method: "DELETE" })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  tasExerciseTags: () => {
    return request(`/tas/exercises/tags`, { method: "GET" })
  },

  /**
   * @type {(workoutId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['TasPackagePagedArrayEssential']>}
   */
  tasPackageIndex: (workoutId, params) => {
    return request(`/tas/workouts/${workoutId}/packages`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(workoutId: number, data?: definitions["TasPackageForm"]) => Promise<import('./data').default['any']>}
   */
  tasPackageCreate: (workoutId, data) => {
    return request(`/tas/workouts/${workoutId}/packages`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasPackageDefault']>}
   */
  tasPackageShow: (id) => {
    return request(`/tas/packages/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasPackageForm"]) => Promise<import('./data').default['any']>}
   */
  tasPackageUpdate: (id, data) => {
    return request(`/tas/packages/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasPackageDestroy: (id) => {
    return request(`/tas/packages/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasPackageSync: (id) => {
    return request(`/tas/packages/${id}/sync`, { method: "POST" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasPracticeDetail']>}
   */
  tasPracticeShow: (id) => {
    return request(`/tas/practices/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasPracticeForm"]) => Promise<import('./data').default['any']>}
   */
  tasPracticeUpdate: (id, data) => {
    return request(`/tas/practices/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasPracticeDestroy: (id) => {
    return request(`/tas/practices/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(stepId: number, data?: definitions["TasPracticeForm"]) => Promise<import('./data').default['any']>}
   */
  tasPracticeCreate: (stepId, data) => {
    return request(`/tas/steps/${stepId}/practices`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasScreenDetail']>}
   */
  tasScreenShow: (id) => {
    return request(`/tas/screens/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasScreenForm"]) => Promise<import('./data').default['any']>}
   */
  tasScreenUpdate: (id, data) => {
    return request(`/tas/screens/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasScreenDestroy: (id) => {
    return request(`/tas/screens/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(bigCircleId: number, data?: definitions["TasScreenForm"]) => Promise<import('./data').default['any']>}
   */
  tasScreenCreate: (bigCircleId, data) => {
    return request(`/tas/big_circles/${bigCircleId}/screens`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number, data?: definitions["TasPracticeForm"]) => Promise<import('./data').default['any']>}
   */
  tasScreenUpdatePractices: (id, data) => {
    return request(`/tas/screens/${id}/update_practices`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasSmallCircleDetail']>}
   */
  tasSmallCircleShow: (id) => {
    return request(`/tas/small_circles/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasSmallCircleForm"]) => Promise<import('./data').default['any']>}
   */
  tasSmallCircleUpdate: (id, data) => {
    return request(`/tas/small_circles/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasSmallCircleDestroy: (id) => {
    return request(`/tas/small_circles/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(screenId: number, data?: definitions["TasSmallCircleForm"]) => Promise<import('./data').default['any']>}
   */
  tasSmallCircleCreate: (screenId, data) => {
    return request(`/tas/screens/${screenId}/small_circles`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasStationDetail']>}
   */
  tasStationShow: (id) => {
    return request(`/tas/stations/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasStationForm"]) => Promise<import('./data').default['any']>}
   */
  tasStationUpdate: (id, data) => {
    return request(`/tas/stations/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasStationDestroy: (id) => {
    return request(`/tas/stations/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(screenId: number, data?: definitions["TasStationForm"]) => Promise<import('./data').default['any']>}
   */
  tasStationCreatebyscreen: (screenId, data) => {
    return request(`/tas/screens/${screenId}/stations`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(smallCircleId: any, data?: definitions["TasStationForm"]) => Promise<import('./data').default['any']>}
   */
  tasStationCreatebysmallcircle: (smallCircleId, data) => {
    return request(`/tas/small_circles/${smallCircleId}/stations`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasStepDetail']>}
   */
  tasStepShow: (id) => {
    return request(`/tas/steps/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasStepForm"]) => Promise<import('./data').default['any']>}
   */
  tasStepUpdate: (id, data) => {
    return request(`/tas/steps/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasStepDestroy: (id) => {
    return request(`/tas/steps/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(stationId: number, data?: definitions["TasStepForm"]) => Promise<import('./data').default['any']>}
   */
  tasStepCreate: (stationId, data) => {
    return request(`/tas/stations/${stationId}/steps`, { method: "POST", data })
  },

  /**
   * @type {(id: number, params: {position?: number}) => Promise<import('./data').default['any']>}
   */
  tasStepMoveTo: (id, params) => {
    return request(`/tas/steps/${id}/move_to`, { method: "PUT", params })
  },

  /**
   * @type {(params: {query?: string, page?: number, pageSize?: number}) => Promise<import('./data').default['PlatformTasWorkoutOutlinePagedArrayEssential']>}
   */
  tasPackageWorkoutOutlineList: (params) => {
    return request(`/tas/workout_outlines`, { method: "GET", params })
  },

  /**
   * @type {(packageId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['PlatformTasWorkoutOutlinePagedArrayEssential']>}
   */
  tasPackageWorkoutOutlineIndex: (packageId, params) => {
    return request(`/tas/packages/${packageId}/workout_outlines`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(packageId: number, data?: definitions["PlatformTasWorkoutOutlineCreateForm"]) => Promise<import('./data').default['any']>}
   */
  tasPackageWorkoutOutlineCreate: (packageId, data) => {
    return request(`/tas/packages/${packageId}/workout_outlines`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasComingCourseTas']>}
   */
  tasWorkoutOutlinePreview: (id) => {
    return request(`/tas/workout_outlines/${id}/preview`, { method: "GET" })
  },

  /**
   * @type {(id: number, params: {type?: string}) => Promise<import('./data').default['PlatformTasWorkoutOutlineDetail']>}
   */
  tasWorkoutOutlineShow: (id, params) => {
    return request(`/tas/workout_outlines/${id}`, { method: "GET", params })
  },

  /**
   * @type {(id: number, data?: definitions["PlatformTasWorkoutOutlineEditForm"]) => Promise<import('./data').default['any']>}
   */
  tasWorkoutOutlineUpdate: (id, data) => {
    return request(`/tas/workout_outlines/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(workoutOutlineId: number) => Promise<import('./data').default['TasWorkoutOutlineWithPlaylistItems']>}
   */
  tasWorkoutOutlinePlaylistItems: (workoutOutlineId) => {
    return request(`/tas/workout_outlines/${workoutOutlineId}/playlist_items`, {
      method: "GET",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasWorkoutOutlineRefreshPlaylist: (id) => {
    return request(`/tas/workout_outlines/${id}/refresh_playlist`, {
      method: "PUT",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasWorkoutOutlineCertificate: (id) => {
    return request(`/tas/workout_outlines/${id}/certificate`, { method: "PUT" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasWorkoutOutlineAbandon: (id) => {
    return request(`/tas/workout_outlines/${id}/abandon`, { method: "PUT" })
  },

  /**
         * @type {(id: number, data?: {
"source_outline_id"?: number;

}) => Promise<import('./data').default['any']>}
         */
  tasWorkoutOutlineCopyExercises: (id, data) => {
    return request(`/tas/workout_outlines/${id}/copy_exercises`, {
      method: "PUT",
      data,
    })
  },

  /**
         * @type {(id: number, data?: {
"source_outline_id"?: number;

}) => Promise<import('./data').default['any']>}
         */
  tasWorkoutOutlineCopyCoachingCue: (id, data) => {
    return request(`/tas/workout_outlines/${id}/copy_coaching_cue`, {
      method: "PUT",
      data,
    })
  },

  /**
         * @type {(id: number, data?: {
"source_big_circle_id"?: number;
"position"?: number;

}) => Promise<import('./data').default['any']>}
         */
  tasWorkoutOutlineInsertBigCircle: (id, data) => {
    return request(`/tas/workout_outlines/${id}/insert_big_circle`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasWorkoutOutlineSync: (id) => {
    return request(`/tas/workout_outlines/${id}/sync`, { method: "POST" })
  },

  /**
   * @type {(params: {workflowState?: ('certificated') | ('new') | ('abandoned'), query?: { [key: string]: any }, page?: number, pageSize?: number}) => Promise<import('./data').default['TasWorkoutVersionPagedArrayEssential']>}
   */
  tasWorkoutVersionList: (params) => {
    return request(`/tas/workout_versions`, { method: "GET", params })
  },

  /**
   * @type {(workoutId: number, params: {workflowState?: ('certificated') | ('new') | ('abandoned'), page?: number, pageSize?: number}) => Promise<import('./data').default['TasWorkoutVersionPagedArrayEssential']>}
   */
  tasWorkoutVersionIndex: (workoutId, params) => {
    return request(`/tas/workouts/${workoutId}/workout_versions`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(workoutId: number, data?: definitions["TasWorkoutVersionForm"]) => Promise<import('./data').default['any']>}
   */
  tasWorkoutVersionCreate: (workoutId, data) => {
    return request(`/tas/workouts/${workoutId}/workout_versions`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasWorkoutVersionDefault']>}
   */
  tasWorkoutVersionShow: (id) => {
    return request(`/tas/workout_versions/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasWorkoutVersionForm"]) => Promise<import('./data').default['any']>}
   */
  tasWorkoutVersionUpdate: (id, data) => {
    return request(`/tas/workout_versions/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasWorkoutVersionDestroy: (id) => {
    return request(`/tas/workout_versions/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasWorkoutVersionCertificate: (id) => {
    return request(`/tas/workout_versions/${id}/certificate`, { method: "PUT" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasWorkoutVersionAbandon: (id) => {
    return request(`/tas/workout_versions/${id}/abandon`, { method: "PUT" })
  },

  /**
   * @type {(id: number, params: {source_version_id?: number}) => Promise<import('./data').default['any']>}
   */
  tasWorkoutVersionUpgradeFromOtherVersion: (id, params) => {
    return request(`/tas/workout_versions/${id}/upgrade_from_other_version`, {
      method: "PUT",
      params,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasWorkoutVersionSync: (id) => {
    return request(`/tas/workout_versions/${id}/sync`, { method: "POST" })
  },

  /**
   * @type {(params: {brand_id?: number, active?: string, usage?: string}) => Promise<import('./data').default['TasWorkoutEssential'][]>}
   */
  tasWorkoutList: (params) => {
    return request(`/tas/workouts/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {query?: string, product_type?: string, provider?: string, active?: string, page?: number, pageSize?: number}) => Promise<import('./data').default['TasWorkoutPagedArrayEssential']>}
   */
  tasWorkoutIndex: (params) => {
    return request(`/tas/workouts`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["TasWorkoutForm"]) => Promise<import('./data').default['any']>}
   */
  tasWorkoutCreate: (data) => {
    return request(`/tas/workouts`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasWorkoutDefault']>}
   */
  tasWorkoutShow: (id) => {
    return request(`/tas/workouts/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasWorkoutForm"]) => Promise<import('./data').default['any']>}
   */
  tasWorkoutUpdate: (id, data) => {
    return request(`/tas/workouts/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasWorkoutDestroy: (id) => {
    return request(`/tas/workouts/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasWorkoutSync: (id) => {
    return request(`/tas/workouts/${id}/sync`, { method: "POST" })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  tasWorkoutProviders: () => {
    return request(`/tas/workouts/providers`, { method: "GET" })
  },
}
