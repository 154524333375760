import { API } from '@/services/platform'
import { PUBLIC_API } from '@/services/public'
import { removeStorage, setStorage } from '@/utils-future/storage'
import { getToken } from '@/utils/auth'

function buildPermissionTree(permissions) {
  let permissionTree = {}
  permissions.forEach(permission => {
    let [parentName, name] = permission.fullName.split("/")

    let lv1, lv2
    if (parentName) {
      [lv1, lv2] = [parentName, name]
    } else {
      lv1 = name
    }

    if (!permissionTree.hasOwnProperty(lv1)) {
      permissionTree[lv1] = []
    }

    if (lv2 && !permissionTree[lv1].includes(lv2)) {
      permissionTree[lv1].push(lv2)
    }
  })
  return permissionTree
}

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    internal: false,
    permissions: {},
    manageAllStudios: null,
    role: null,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_INTERNAL: (state, internal) => {
      state.internal = internal
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = buildPermissionTree(permissions)
    },
    SET_ROLE: (state, role) => {
      state.role = role
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        PUBLIC_API.authPassword({ login: username, password: userInfo.password })
          .then(resp => {
            // setToken(resp.token)
            setStorage('token', resp.token)
            commit('SET_TOKEN', resp.token)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    fetchProfile({ commit, state }) {
      return new Promise((resolve, reject) => {
        API.generalCurrentAdmin().then(res => {
          commit('SET_NAME', res.name)
          commit('SET_ROLE', res.role)
          commit('SET_INTERNAL', res.internal)
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },

    logOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', null)
        removeStorage('token')
        removeStorage('identifier')
        resolve()
      })
    },
  },
}

export default user
