import Layout from 'views/layout/Layout'

export default {
  path: '/ktas',
  redirect: '/ktas/stages',
  name: 'ktas',
  component: Layout,
  title: '少儿运动课程',
  permission: ['ktas'],
  hidden: false,
  icon: 'grouptraining',
  children: [
    {
      path: 'workouts',
      component: () => import('views/ktas/workout/Index'),
      title: '课程品类',
      keepAlive: true,
      name: 'KtasWorkoutIndex',
      permission: ['ktas', 'workouts'],
      hidden: false,
    },
    {
      path: 'workouts/:workoutId/outlines',
      component: () => import('views/ktas/outline/Index'),
      title: '课程教案',
      keepAlive: true,
      name: 'KtasOutlineIndex',
      children: [
        {
          path: ':outlineId/sections',
          component: () => import('views/ktas/section/Index'),
          title: '课程小节',
          keepAlive: true,
          name: 'KtasSectionIndex',
          root: true
        },
      ]
    },
    // {
    //   path: 'stages',
    //   component: () => import('views/ktas/stage/Index'),
    //   title: '课程阶段',
    //   keepAlive: true,
    //   name: 'KtasStageIndex',
    //   permission: ['ktas', 'stages'],
    //   hidden: false,
    // },
    
    // {
    //   path: 'character_trainings',
    //   component: () => import('views/ktas/character_training/Index'),
    //   title: '品格培养',
    //   keepAlive: true,
    //   name: 'KtasCharacterTrainingIndex',
    //   permission: ['ktas', 'character_trainings'],
    //   hidden: false,
    // },
    // {
    //   path: 'perception_trainings',
    //   component: () => import('views/ktas/perception_training/Index'),
    //   title: '感知训练',
    //   keepAlive: true,
    //   name: 'KtasPerceptionTrainingIndex',
    //   permission: ['ktas', 'perception_trainings'],
    //   hidden: false,
    // },
    // {
    //   path: 'games',
    //   component: () => import('views/ktas/game/Index'),
    //   title: '游戏库',
    //   keepAlive: true,
    //   name: 'KtasGameIndex',
    //   permission: ['ktas', 'games'],
    //   hidden: false,
    // },
    {
      path: 'training_methods',
      component: () => import('views/ktas/training_method/Index'),
      title: '训练方式',
      keepAlive: true,
      name: 'KtasTrainingMethodIndex',
      permission: ['ktas', 'training_methods'],
      hidden: false,
    },
  ],
}
