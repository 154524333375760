<template>
  <el-button-group class="item">
    <el-button 
      v-for="obj in showData"
      :class="{'active': obj.value === value}"
      :key="obj.value"
      :data-value='obj.value'
      @click="handleClick"
      size='small'
    >
      {{obj.label}}
    </el-button>
  </el-button-group>
</template>

<script>
export default {
  name: 'MfButtonGroup',
  
  props: {
    data: Array,
    value: null,
    all: {
      type: Boolean,
      default: false,
    },
    type: {
      validator: value => {
        let bool = ['date', 'active', 'product'].includes(value)
        if (!bool) {
          throw Error('❌type只能为date、active、product中的一种。')
        } else {
          return true
        }
      },
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      active: this.value,
    }
  },

  computed: {
    showData() {
      let result = null
      if (this.data) {
        result = this.data
      } else {
        if (this.type === 'date') {
          result = dateData
        }
        if (this.type === 'active') {
          result = activeData
        }
        if (this.type === 'product') {
          result = productData
        }
      }
      if (this.all && result[0].label !== '全部') {
        result = [...result]
        result.push({
          label: '全部',
          value: '',
        })
      }
      return result
    },
  },

  methods: {
    handleClick(e) {
      let { value } = e.target.dataset
      if (this.type === 'active') {
        value = value === 'true'
      }
      this.$emit('change', value)
    },
  },
}

const dateData = [
  {
    label: '周',
    value: 'week',
  },
  {
    label: '月',
    value: 'month',
  },
]

const activeData = [
  {
    label: '激活',
    value: true,
  },
  {
    label: '暂停',
    value: false,
  },
]

const productData = [
  {
    label: '团课',
    value: 'group_training',
  },
  {
    label: '私教',
    value: 'elite_training',
  },
]
</script>

<style lang="scss" scoped>
.el-button + .el-button {
  margin-left: 0;
}

/deep/ .el-button {
  span {
    pointer-events: none;
  }

  &:hover,
  &:focus,
  &.active {
    border-color: $primary;
    background-color: $primary;
    color: #ffffff;
  }
}
</style>
