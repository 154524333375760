import { API } from '@/services/platform'
import { injectDefaultValue } from '@/utils/'
import { cloneDeep } from 'lodash'

const ACTION_TYPE_KEY = '__actionType'

export default {
  data() {
    return {
      ACTION_TYPE_KEY,
      formData: {},
      isLoading: false,
    }
  },

  computed: {
    title() {
      return this.actionType === 'update' ? `编辑${this.resourceName}` : `添加${this.resourceName}`
    },

    loading() {
      return this.isLoading
    },

    actionType() {
      return this.id ? 'update' : 'create'
    },
    
    dialogName() {
      return `${this.resource}FormDialog`
    },
    
    formConfig() {
      if (this.formData) {
        let config = cloneDeep(this.config)
        return injectDefaultValue(config, this.formData)
      } else {
        return this.config
      }
    }
  },

  methods: {
    fetchResource() {
      if (this.id) {
        this.isLoading = true
        API[`${this.resource}Show`].call(this, this.id).then(res => {
          this.isLoading = false
          this.formData = res
        })
      }
    },

    updateResource(formData) {
      API[`${this.resource}Update`].call(this, this.id, formData)
        .then(res => {
          this.$message({
            message: `修改${this.resourceName}成功`,
            type: 'success',
          })
          this.handleClose(true)
        })
    },

    createResource(formData) {
      API[`${this.resource}Create`].call(this, formData)
        .then(res => {
          this.$message({
            message: `创建${this.resourceName}成功`,
            type: 'success',
          })
          this.handleClose(true)
        })
    },

    resetForm() {
      this.formData = {}
      this.$refs.form.reset()
    },

    submitForm(formData) {
      formData = _.omit(formData, ACTION_TYPE_KEY)
      if (this.actionType === 'update') {
        this.updateResource(formData)
      } else {
        this.createResource(formData)
      }
    },
    

    handleClose(reload) {
      this.$emit('close', reload)
    },
  },
}