<template>
  <div>
    <div v-if='type === "coach"'>
      <template v-if='isEmpty'>--</template>
      <div v-else class="mf-avatar" :style='{cursor: url === false ? "auto": "pointer"}' @click="goDetail('coachDetail', data.id)">
        <img :src="avatar(data)" alt="avatar">
        <div class="name">{{$get(data, 'name')}}</div>
      </div>
    </div>
    <template v-else>
      <el-popover popper-class="member-card-popper" placement="right" width="700" trigger="click" visible-arrow='false' @show='fetchData'>
        <div slot='reference'>
          <template v-if='isEmpty'>--</template>
          <div v-else class="mf-avatar" :style='{cursor: url === false ? "auto": "pointer"}'>
            <img :src="avatar(data)" alt="avatar">
            <div class="name">{{$get(data, 'name')}}</div>
          </div>
        </div>

        <div class="mf-member-profile" v-loading='isLoading'>
          <div v-if="memberData">
            <div class="profile">
              <div class="profile-left">
                <img :src="avatar(memberData)" class="avatar">
                <div class="name">
                  <span class="name-box">{{memberData.name}}</span>

                  <i class="icon icon-female" v-if="memberData.gender === 'female'"></i>
                  <i class="icon icon-male" v-else-if="memberData.gender === 'male'"></i>
                </div>
              </div>
              <div class="profile-right">
                <div class="item">
                  <div class='label'>累计推荐</div>
                  <div class="more" v-if="memberData.invitationCount">
                    <!-- 
                    <div class="referral-avatar-wrapper">
                      <img :src="avatar(item.referral)" v-for="item in memberData.referrals.slice(0, 5)" :key='item.id' />
                    </div>
                    -->
                    <span>{{memberData.invitationCount}}人</span>
                  </div>
                  <div class="more" v-else>
                    <span>0人</span>
                  </div>
                </div>
                <div class='go-detail' @click='goDetail("memberDetail", data.id)'>
                  查看详情
                </div>
              </div>
            </div>
            <div class="data-info">
              <div class="info-row">
                <div class="item">
                  <div class='label'>加入时间</div>
                  <span>{{parseTime(memberData.joinedAt)}}</span>
                </div>
                <div class="item referrer">
                  <div class='label'>转介绍人</div>
                  <span @click="goDetail('memberDetail', memberData.inviter.id)" v-if="memberData.inviter">
                    <div>{{memberData.inviter.name}}</div>
                    <div>{{memberData.inviter.phone}}</div>
                  </span>
                  <span class="no-data" v-else></span>
                </div>
                <div class="item">
                  <div class='label'>会员电话</div>
                  <span>{{memberData.phone || '--'}}</span>
                </div>
                <div class="item">
                  <div class='label'>年龄</div>
                  <span>{{memberData.age}}</span> 岁
                </div>
                <div class="item">
                  <div class='label'>体重</div>
                  <span v-if="memberData.weight">{{memberData.weight}}</span>
                  <span class="no-data" v-else></span> kg
                </div>
                <div class="item">
                  <div class='label'>最大心率</div>
                  <span v-if="memberData.maxHr">{{memberData.maxHr}}</span>
                  <span class="no-data" v-else></span> bpm
                </div>
              </div>

              <div class='info-row' v-if='$get(memberData, "trainingProfile")'>
                <div class="item">
                  <div class='label'>常去门店</div>
                  <span v-if="$get(memberData, 'primaryStudio.name')">{{($get(memberData, 'primaryStudio.name'))}}</span>
                  <span v-else class='no-data'></span>
                </div>
                <div class="item">
                  <div class='label'>运动基础</div>
                  <span v-if="memberData.trainingProfile.trainingLevel">
                    {{memberData.trainingProfile.trainingLevel}}
                  </span>
                  <span class="no-data" v-else></span>
                </div>
                <div class="item">
                  <div class='label'>运动目的</div>
                  <span v-if="memberData.trainingProfile.trainingPurpose">
                    {{memberData.trainingProfile.trainingPurpose}}
                  </span>
                  <span class="no-data" v-else></span>
                </div>
                <div class="item">
                  <div class='label'>到店方式</div>
                  <span v-if="memberData.trainingProfile.traffic">
                    {{memberData.trainingProfile.traffic}}
                  </span>
                  <span class="no-data" v-else></span>
                </div>
                <div class="item">
                  <div class='label'>来源</div>
                  <span v-if="memberData.trainingProfile.channel">
                    {{memberData.trainingProfile.channel}}
                  </span>
                  <span class="no-data" v-else></span>
                </div>
              </div>
            </div>
            <!-- <div class="notes-wrapper" v-if="memberData.notes">
              <div class="item">
                <div class="label">备注信息</div>
                <member-notes
                  :id='memberId'
                  :notes='memberData.notes'
                />
              </div>
            </div> -->
          </div>
        </div>

      </el-popover>
    </template>
  </div>
</template>

<script>
import { API } from '@/services/platform'
import { parseTime } from '@/utils/date';

export default {
  name: 'MfAvatar',

  props: {
    data: {
      type: null,
      // validator: obj => {
      //   if (!obj.hasOwnProperty('avatarUrl') || !obj.hasOwnProperty('name')) {
      //     return false
      //   }
      //   return true
      // }
    },
    type: {
      type: String,
      default: 'member',
    },
    url: {
      type: null,
    },
  },

  data() {
    return {
      isLoading: true,
      memberData: null,
    };
  },

  computed: {
    isEmpty() {
      return !this.data.avatar && !this.data.name;
    },

    memberId() {
      return this.data?.id;
    },
  },

  methods: {
    parseTime,

    fetchData() {
      this.isLoading = true;
      API.memberMemberShow(this.memberId).then((res) => {
        this.memberData = res;
        this.isLoading = false;
      });
    },

    goDetail(name, id) {
      let routeData = this.$router.resolve({
        name,
        params: {
          [name === 'memberDetail' ? 'memberId' : 'coachId']: id,
        },
      });

      window.open(routeData.href, '_blank');
    },

    avatar(person) {
      return this.$get(person, 'avatar.url', require('@/assets/images/default_avatar.png'))
    }
  },
};
</script>

<style lang='scss' scoped>
.mf-avatar {
  display: flex;
  align-items: center;
  padding-left: 15%;

  img {
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 8px;
    border-radius: 50%;
  }

  .name {
    font-size: 14px;
    line-height: 1.571428571;
    color: #5f6265;
  }
}

.mf-member-profile {
  padding: 10px;
  min-height: 144px;

  .name-box {
    max-width: 8em;
  }

  .go-detail {
    position: relative;
    font-size: 14px;
    color: $primary;
    padding-right: 12px;
    padding-top: 2px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      border: 1px solid $primary;
      border-left: 0;
      border-top: 0;
      right: 0;
      @include center(y);
      transform: rotate(-45deg) translate(0, -50%);
    }
  }

  .profile {
    display: flex;
    justify-content: space-between;
  }

  .profile-left,
  .profile-right {
    display: flex;
    align-items: center;
  }

  .avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 16px;
  }

  .name {
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
    display: flex;
    align-items: center;
    line-height: 1;
    margin-right: 24px;

    .icon {
      font-size: 12px;
      border-radius: 50%;
      color: rgba(0, 0, 0, 0.45);
      border: 1px solid #dcdfe6;
      width: 16px;
      height: 16px;
      display: inline-block;
      text-align: center;
      line-height: 16px;
      margin-left: 8px;
      margin-bottom: -2px;
    }

    .icon-male {
      color: $primary;
    }

    .icon-female {
      color: #e45b5d;
    }
  }

  .no-data {
    &:after {
      color: rgba(#000, 0.25);
      content: '--';
      margin-right: 2px;
    }
  }

  .profile-right {
    align-items: flex-start;

    .item {
      color: #5f6265;
      font-size: 14px;
      line-height: 22px;

      &:not(:last-child) {
        margin-right: 32px;
      }

      .icon {
        font-size: 14px;
        margin-right: 3px;
      }

      &:nth-child(1) .icon {
        color: $primary;
      }

      &:nth-child(2) .icon {
        color: $secondary;
      }
    }
  }

  .data-info {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    font-size: 14px;
    line-height: 22px;
  }

  .info-row {
    width: 100%;
    display: flex;

    & + .info-row {
      margin-top: 20px;
    }
  }

  .referrer {
    cursor: pointer;
  }

  .info-row,
  .profile-right,
  .notes-wrapper {
    display: flex;

    .item {
      margin-right: 32px;
      max-width: 10em;
    }

    .label {
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 8px;
    }

    span,
    .more,
    .notes {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
    }

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    .more {
      display: flex;
      align-items: center;
      cursor: inherit;
      position: relative;
      padding-right: 12px;

      .no-link {
        cursor: auto;
      }

      img + span {
        margin-left: 8px;
      }

      .referral-avatar-wrapper {
        display: flex;

        img {
          position: relative;
          display: block;
        }

        img:not(:first-child) {
          margin-left: -10px;
        }

        & + span {
          margin-left: 8px;
        }
      }
    }
  }

  .notes-wrapper {
    margin-top: 16px;

    .item {
      flex: 1;
    }

    .label {
      font-size: 14px;
    }
  }
}
</style>