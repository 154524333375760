<template>
  <el-menu
    class="navbar"
    mode="horizontal"
  >
    <img
      :src="logoUrl"
      alt="logo"
      class="logo"
    >
    <breadcrumb></breadcrumb>
    <el-dropdown
      class="avatar-container"
      trigger="click"
    >
      <div class="avatar-wrapper">
        <strong class="name-avatar">{{name}}</strong>
        <i class="el-icon-caret-bottom"></i>
      </div>
      <el-dropdown-menu
        class="user-dropdown"
        slot="dropdown"
      >
        <router-link
          class="inlineBlock"
          to="/"
        >
          <el-dropdown-item>
            <i class='icon icon-home'></i> 主页
          </el-dropdown-item>
        </router-link>
        <div @click="openDialog('editPassword')">
          <el-dropdown-item>
            <i class='icon icon-lock'></i> 修改密码
          </el-dropdown-item>
        </div>
        <div @click="logout">
          <el-dropdown-item divided>
            <i class='icon icon-logout'></i> 登出
          </el-dropdown-item>
        </div>
      </el-dropdown-menu>
    </el-dropdown>

    <EditPassword @close="closeDialog" />
  </el-menu>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb.vue'
import EditPassword from './EditPassword.vue'

export default {
  components: {
    Breadcrumb,
    EditPassword,
  },

  computed: {
    ...mapGetters(['name']),

    ...mapState({
    }),

    logoUrl() {
      return require('@/assets/images/logo_black.png')
    },
  },

  created() {
  },

  methods: {
    openDialog(name) {
      this.$store.commit({
        type: 'OPEN_DIALOG',
        name,
      })
    },

    closeDialog() {
      this.$store.commit('CLOSE_DIALOG')
    },

    logout() {
      this.$store.dispatch('logOut').then(() => {
        location.reload()
      })
    },
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  display: flex;
  align-items: center;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 35px;
    .avatar-wrapper {
      cursor: pointer;
      margin-top: 5px;
      position: relative;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        top: 20px;
        font-size: 12px;
      }
    }
  }
}

.logo {
  width: 120px;
  height: auto;
  margin-left: 40px;
  margin-right: 35px;
}
</style>
