import request from "@/utils/request-future"

export const API = {
  /**
   * @type {(brandId: number) => Promise<import('./data').default['BrandCommunicationDefault'][]>}
   */
  v2PlatformCommunicationIndex: (brandId) => {
    return request(`/v2/platform/brands/${brandId}/communications/`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(brandId: number, data?: definitions["V2PlatformCommunicationCreateRequest"]) => Promise<import('./data').default['BrandCommunicationDefault']>}
   */
  v2PlatformCommunicationCreate: (brandId, data) => {
    return request(`/v2/platform/brands/${brandId}/communications/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(brandId: number, id: number) => Promise<import('./data').default['any']>}
   */
  v2PlatformCommunicationDestroy: (brandId, id) => {
    return request(`/v2/platform/brands/${brandId}/communications/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, productId?: number, brandId?: number, packageId?: number}) => Promise<import('./data').default['PlatformBrandOutlinePermissionDefaultPagedArray']>}
   */
  v2PlatformBrandOutlinePermissionIndex: (params) => {
    return request(`/v2/platform/brand/outline_permissions/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2PlatformBrandOutlinePermissionCreateRequest"]) => Promise<import('./data').default['any']>}
   */
  v2PlatformBrandOutlinePermissionCreate: (data) => {
    return request(`/v2/platform/brand/outline_permissions/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['PlatformBrandOutlinePermissionDefault']>}
   */
  v2PlatformBrandOutlinePermissionShow: (id) => {
    return request(`/v2/platform/brand/outline_permissions/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2PlatformBrandOutlinePermissionDestroy: (id) => {
    return request(`/v2/platform/brand/outline_permissions/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(coachId: number, params: {page?: number, pageSize?: number, ids?: (any)[]}) => Promise<import('./data').default['CoachCommunicationOptionPagedArray']>}
   */
  v2PlatformCoachCommunicationOptions: (coachId, params) => {
    return request(
      `/v2/platform/coach/coaches/${coachId}/communications/options`,
      { method: "GET", params, version: "v2" }
    )
  },

  /**
   * @type {(coachId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['CoachCommunicationDefaultPagedArray']>}
   */
  v2PlatformCoachCommunicationIndex: (coachId, params) => {
    return request(`/v2/platform/coach/coaches/${coachId}/communications/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(coachId: number, data?: definitions["V2PlatformCoachCommunicationCreateRequest"]) => Promise<import('./data').default['CoachCommunicationDefault']>}
   */
  v2PlatformCoachCommunicationCreate: (coachId, data) => {
    return request(`/v2/platform/coach/coaches/${coachId}/communications/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CoachCommunicationDefault']>}
   */
  v2PlatformCoachCommunicationShow: (id) => {
    return request(`/v2/platform/coach/communications/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2PlatformCoachCommunicationDestroy: (id) => {
    return request(`/v2/platform/coach/communications/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, productId?: number, coachId?: number, ids?: (any)[]}) => Promise<import('./data').default['any']>}
   */
  v2PlatformCoachOutlinePermissionOptions: (params) => {
    return request(`/v2/platform/coach/outline_permissions/options`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, productId?: number, coachId?: number}) => Promise<import('./data').default['PlatformCoachOutlinePermissionDefaultPagedArray']>}
   */
  v2PlatformCoachOutlinePermissionIndex: (params) => {
    return request(`/v2/platform/coach/outline_permissions/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2PlatformCoachOutlinePermissionCreateRequest"]) => Promise<import('./data').default['any']>}
   */
  v2PlatformCoachOutlinePermissionCreate: (data) => {
    return request(`/v2/platform/coach/outline_permissions/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2PlatformCoachOutlinePermissionShow: (id) => {
    return request(`/v2/platform/coach/outline_permissions/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2PlatformCoachOutlinePermissionUpdateRequest"]) => Promise<import('./data').default['any']>}
   */
  v2PlatformCoachOutlinePermissionUpdate: (id, data) => {
    return request(`/v2/platform/coach/outline_permissions/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2PlatformCoachOutlinePermissionDestroy: (id) => {
    return request(`/v2/platform/coach/outline_permissions/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(coachId: number, params: {page?: number, pageSize?: number, ids?: (any)[]}) => Promise<import('./data').default['any']>}
   */
  v2PlatformCoachWorkoutVerificationOptions: (coachId, params) => {
    return request(
      `/v2/platform/coach/coaches/${coachId}/workout_verifications/options`,
      { method: "GET", params, version: "v2" }
    )
  },

  /**
   * @type {(coachId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['PlatformCoachWorkoutVerificationDefaultPagedArray']>}
   */
  v2PlatformCoachWorkoutVerificationIndex: (coachId, params) => {
    return request(
      `/v2/platform/coach/coaches/${coachId}/workout_verifications/`,
      { method: "GET", params, version: "v2" }
    )
  },

  /**
   * @type {(coachId: number, data?: definitions["V2PlatformCoachWorkoutVerificationCreateRequest"]) => Promise<import('./data').default['PlatformCoachWorkoutVerificationDefault']>}
   */
  v2PlatformCoachWorkoutVerificationCreate: (coachId, data) => {
    return request(
      `/v2/platform/coach/coaches/${coachId}/workout_verifications/`,
      { method: "POST", data, version: "v2" }
    )
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['PlatformCoachWorkoutVerificationDefault']>}
   */
  v2PlatformCoachWorkoutVerificationShow: (id) => {
    return request(`/v2/platform/coach/workout_verifications/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2PlatformCoachWorkoutVerificationUpdateRequest"]) => Promise<import('./data').default['any']>}
   */
  v2PlatformCoachWorkoutVerificationUpdate: (id, data) => {
    return request(`/v2/platform/coach/workout_verifications/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2PlatformCoachWorkoutVerificationDestroy: (id) => {
    return request(`/v2/platform/coach/workout_verifications/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, keyword?: string}) => Promise<import('./data').default['NotificationDefaultPagedArray']>}
   */
  v2PlatformNotificationIndex: (params) => {
    return request(`/v2/platform/notifications/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(data?: definitions["V2PlatformNotificationCreateRequest"]) => Promise<import('./data').default['NotificationDefault']>}
   */
  v2PlatformNotificationCreate: (data) => {
    return request(`/v2/platform/notifications/`, {
      method: "POST",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['NotificationDefault']>}
   */
  v2PlatformNotificationShow: (id) => {
    return request(`/v2/platform/notifications/${id}`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2PlatformNotificationUpdateRequest"]) => Promise<import('./data').default['NotificationDefault']>}
   */
  v2PlatformNotificationUpdate: (id, data) => {
    return request(`/v2/platform/notifications/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  v2PlatformNotificationDestroy: (id) => {
    return request(`/v2/platform/notifications/${id}`, {
      method: "DELETE",
      version: "v2",
    })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  v2PlatformOperatingBrandSummary: () => {
    return request(`/v2/platform/operating/brands/summary`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, serviceProductList?: (any)[], operatingTagList?: (any)[], operatingAlertList?: (any)[], sort?: string, keyword?: string, expired?: boolean}) => Promise<import('./data').default['V2PlatformBrandBrandOperatingPagedArray']>}
   */
  v2PlatformOperatingBrandIndex: (params) => {
    return request(`/v2/platform/operating/brands/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },

  /**
   * @type {(id: number, data?: definitions["V2PlatformOperatingBrandUpdateRequest"]) => Promise<import('./data').default['V2PlatformBrandBrandOperating']>}
   */
  v2PlatformOperatingBrandUpdate: (id, data) => {
    return request(`/v2/platform/operating/brands/${id}`, {
      method: "PUT",
      data,
      version: "v2",
    })
  },

  /**
   * @type {(brandId: number) => Promise<import('./data').default['any']>}
   */
  v2PlatformOperatingBrandSalesReport: (brandId) => {
    return request(`/v2/platform/operating/brands/${brandId}/sales_report`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(brandId: number) => Promise<import('./data').default['any']>}
   */
  v2PlatformOperatingBrandRevenueReport: (brandId) => {
    return request(`/v2/platform/operating/brands/${brandId}/revenue_report`, {
      method: "GET",
      version: "v2",
    })
  },

  /**
   * @type {(brandId: number) => Promise<import('./data').default['any']>}
   */
  v2PlatformOperatingBrandCoursesAndBookings: (brandId) => {
    return request(
      `/v2/platform/operating/brands/${brandId}/courses_and_bookings`,
      { method: "GET", version: "v2" }
    )
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, brandId: number}) => Promise<import('./data').default['V2PlatformCourseProductOperatingPagedArray']>}
   */
  v2PlatformOperatingCourseProductIndex: (params) => {
    return request(`/v2/platform/operating/course/products/`, {
      method: "GET",
      params,
      version: "v2",
    })
  },
}
