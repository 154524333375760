<template>
  <el-select
    :size="size"
    v-model="usage"
    placeholder="使用方式"
    class="item"
    @change="change"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script>
import fetch from '@/mixins/fetch'

const usages = [
  {
    label: '用于授权',
    value: 'default'
  },
  // {
  //   label: '内容模板',
  //   value: 'content'
  // },
  {
    label: '用于模板',
    value: 'template'
  },
]

export default {
  name: "SpWorkoutUsage",

  // mixins: [fetch],

  props: {
    value: null,
    size: {
      type: String,
      default: "small",
    },
    all: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      options: this.all ? [
        {
          label: "全部",
          value: null,
        }
      ].concat(usages) : usages,
      usage: this.value,
      // fetchApi: this.$API.tasWorkoutProviders
    };
  },

  model: {
    prop: "value",
    event: "change",
  },

  watch: {
    // sourceData (val) {
    //   if(val) {
    //     if (this.all) {
    //       this.options = [
    //         {
    //           label: "全部使用方式",
    //           value: null,
    //         },
    //         ...val,
    //       ]
    //     }else{
    //       this.options = val
    //     }
    //   }
    // },

    value(val) {
      this.usage = this.value
    }
  },

  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
