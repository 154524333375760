/**
 * 
 * @param {'identifier' | 'token'} key 
 * @returns {any}
 */
export const getStorage = (key) => {
  return localStorage.getItem(key)
}

/**
 * 
 * @param {'identifier' | 'token'} key 
 * @param {any} value
 */
export const setStorage = (key, value) => {
  localStorage.setItem(key, value)
}

/**
 * 
 * @param {'identifier' | 'token'} key 
 * @param {any} value
 */
export const removeStorage = (key) => {
  localStorage.removeItem(key)
}
