import Layout from 'views/layout/Layout'

export default {
  path: '/operating',
  redirect: '/operating/course_products',
  name: 'operating',
  component: Layout,
  title: '授权管理',
  permission: ['operating'],
  hidden: false,
  icon: 'setting',
  children: [
    {
      path: 'course_products',
      component: () => import('views/operating/CourseProducts'),
      title: '场馆授权管理',
      keepAlive: true,
      name: 'OperatingCourseProducts',
      permission: ['operating', 'product'],
      hidden: false,
    },
    {
      path: 'coach_products',
      component: () => import('views/operating/CoachProducts'),
      title: '教练授权管理',
      keepAlive: true,
      name: 'OperatingCoachProducts',
      permission: ['operating', 'coach'],
      hidden: false,
    },
    {
      path: 'coaches/:coachId/coach_products',
      component: () => import('views/coach/product/Index'),
      title: '团课产品管理',
      keepAlive: true,
      name: 'OperatingCoachProductsList',
      permission: ['operating', 'coach'],
      hidden: true,
    },
  ],
}
