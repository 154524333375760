import Vue from 'vue'
import { API } from '@/services/platform'
import { omit } from 'lodash'

const global = {
  state: {
    activeDialog: [],
    dialogPayload: {
      lastDialogName: '',
    },
    studios: [],
    tags: [],
  },

  mutations: {
    SET_STUDIOS: (state, { data }) => {
      state.studios = data
    },

    SET_TAGS: (state, { data }) => {
      state.tags = data
    },

    OPEN_DIALOG: (state, { name, payload = [] }) => {
      state.activeDialog.push(name)
      state.dialogPayload.lastDialogName = name
      Vue.set(state.dialogPayload, name, payload)
      // state.dialogPayload.push(payload)
    },

    CLOSE_DIALOG: (state, payload) => {
      let index = state.activeDialog.length - 1
      if (index >= 0) {
        
        const actualName = payload?.name || state.dialogPayload.lastDialogName
        if (state.dialogPayload[actualName]) {
          state.activeDialog.splice(index, 1)
          Vue.delete(state.dialogPayload, actualName)
        }
      }
    },
  },

  actions: {
    getStudios({ state, commit }) {
      if (state.studios.length !== 0) {
        return
      }
      API.studioStudioList().then(res => {
        let { data } = res
        commit({
          type: 'SET_STUDIOS',
          data,
        })
      })
    },

    getTags({ state, commit }) {
      if (state.tags.length !== 0) {
        return
      }
      // let params = { target: 'member' }
      // fetchTags(params).then(res => {
      //   let { data } = res
      //   commit({
      //     type: 'SET_TAGS',
      //     data,
      //   })
      // })
    },
  },

  getters: {
    getStudioByName: store => {
      return name => {
        let studio = store.studios.find(obj => {
          return obj.name === `SPEEDPLAY·${name}`
        })
        return studio
      }
    },
  },
}

export default global
