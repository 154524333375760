import { PAGINATION_INFO } from '@/constant/entries'
import { filterFalsy } from '@/utils/'
import { isEmpty } from "lodash"
import { getCurrentMonth } from '@/utils/date'

// 用于专门的详情页
export default {
  data: function () {
    return {
      isLoading: true,
      total: null,
      sourceData: null,
      filter: {
        page: 1,
        pageSize: PAGINATION_INFO.pageSize,
        from: null,
      },
    }
  },

  computed: {
    propsData() {
      let result = {
        data: this.sourceData,
        columnsConfig: this.columnsConfig,
        total: this.total,
        page: this.filter.page,
        isLoading: this.isLoading,
      }
      return result
    },
  },

  watch: {
    filter: {
      handler: function () {
        let params = this.makeParams()
        console.log('emit', params)
        this.fetchData(params)
      },
      deep: true,
    },
  },

  created() {
    let { query } = this.$route

    if (isEmpty(query)) {
      let params = this.makeParams()
      this.fetchData(params)
    } else {
      this.filter = {
        ...this.filter,
        ...query,
      }
    }
    console.log('query: ', query, isEmpty(query), this.filter)
  },

  methods: {
    fetchData(params) {
      this.isLoading = true
      if (!params) {
        let { page, pageSize } = this.filter
        params = { page, pageSize }
      }
      if (!this.fetchApi || !this.id) {
        return false
      }
      this.fetchApi(this.id, params).then(res => {
        this.sourceData = this.formatData(res.data)
        this.isLoading = false
        this.total = parseInt(res.headers.total, 10)
      })
    },

    formatData(data) {
      return data
    },

    makeParams() {
      let params = { ...this.filter }
      if (params.state === 'currentMonth') {
        params.from = getCurrentMonth()
      } else if (params.state === 'all') {
        params.from = ''
      }
      return filterFalsy(params)
    },

    changePage(value) {
      this.filter.page = value
    },

    reload() {
      let params = this.makeParams()
      this.fetchData(params)
    },
  },
}
