import Layout from 'views/layout/Layout'

export default {
  path: '/brand_operating',
  redirect: '/brand_operating/list',
  component: Layout,
  name: 'BrandOperting',
  title: '品牌运营',
  hidden: false,
  icon: 'store',
  permission: ['brand_operating'],
  children: [
    {
      path: 'list',
      component: () => import('views/brand_operating/Index'),
      name: 'BrandOperatingList',
      title: '品牌运营',
      keepAlive: true,
      permission: ['brand_operating'],
      hidden: false
    },
  ]
}