import MfCard from './MfCard'
import MfDialog from './MfDialog'
import MfEditor from './MfEditor'
import MfFilterTable from './MfFilterTable'
import MfImageUpload from './MfImageUpload'
import MfForm from './MfForm'
import MfInfo from './MfInfo'
import MfMasonry from './MfMasonry'
import MfResourceFormDialog from './MfResourceFormDialog'
import MfOverviewCard from './MfOverviewCard'
import MfOverviewCard_v1 from './MfOverviewCard_v1'
import MfSingleCheckbox from './MfSingleCheckbox'
import MfTable from './MfTable'
import MfRadio from './MfRadio'
import MfEditable from './MfEditable'
import MfTabs from './MfTabs'
import MfHorizontalBar from './MfHorizontalBar'
import MfAvatar from './MfAvatar'
import MfImageBox from './MfImageBox'
import MfImagePreview from './MfImagePreview'
import MfImageDialog from './MfImageDialog'
import MfTagList from './MfTagList'
import MfDateController from './MfDateController'
import { render, renderValue } from './render'

import filterComponents from './filter/components'

let components = [
  MfCard,
  MfDialog,
  MfEditor,
  MfFilterTable,
  MfImageUpload,
  MfForm,
  MfOverviewCard,
  MfOverviewCard_v1,
  MfInfo,
  MfMasonry,
  MfSingleCheckbox,
  MfResourceFormDialog,
  MfTable,
  MfRadio,
  MfEditable,
  MfTabs,
  MfHorizontalBar,
  MfAvatar,
  MfTagList,
  MfDateController,
  MfImageBox,
  MfImagePreview,
  MfImageDialog,
  render,
  renderValue,
]

components = [
  ...components,
  ...filterComponents,
]

function registerComponent(vue) {
  components.forEach(component => {
    vue.component(component.name, component)
  })
}


export default registerComponent
