import Layout from 'views/layout/Layout'

export default {
  path: '/setting',
  redirect: '/setting/admins',
  name: 'setting',
  component: Layout,
  title: '系统管理',
  permission: ['admin'],
  hidden: false,
  icon: 'setting',
  children: [
    {
      path: 'admins',
      component: () => import('views/setting/admins/Index'),
      title: '帐号管理',
      keepAlive: true,
      name: 'SettingAdmins',
      permission: ['admin', 'admins'],
      hidden: false,
    },
    {
      path: 'roles',
      component: () => import('views/setting/permissions/Index'),
      title: '权限管理',
      keepAlive: true,
      name: 'SettingPermissions',
      permission: ['admin', 'roles'],
      hidden: false,
    },
  ],
}
