import Layout from 'views/layout/Layout'

export default {
  path: '/notification',
  redirect: '/notification/list',
  component: Layout,
  name: 'Notifications',
  title: '品牌通知',
  hidden: false,
  icon: 'send',
  permission: ['notification'],
  children: [
    {
      path: 'list',
      component: () => import('views/notification/Index'),
      name: 'NotificationList',
      title: '品牌通知',
      keepAlive: true,
      permission: ['notification'],
      hidden: false
    },
  ]
}
