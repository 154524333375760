import Tags from "./Tags.vue";
import ButtonGroup from "./ButtonGroup.vue";
import Gender from "./Gender.vue";
import Search from "./Search.vue";
import ProductType from "./ProductType.vue";
import PaymentMethod from "./PaymentMethod.vue";
import DatePicker from "./DatePicker.vue";

export {
  Tags,
  ButtonGroup,
  Gender,
  Search,
  ProductType,
  PaymentMethod,
  DatePicker,
}