import Layout from 'views/layout/Layout'

export default {
  path: '/brand',
  redirect: '/brand/brands',
  name: 'brand',
  component: Layout,
  title: '品牌管理',
  permission: ['brand'],
  hidden: false,
  icon: 'site',
  children: [
    {
      path: 'brands',
      component: () => import('views/brand/brands/Index'),
      title: '品牌列表',
      keepAlive: true,
      name: 'BrandIndex',
      permission: ['brand', 'brands'],
      hidden: false,
      children: [
        {
          path: ':brandId/products',
          component: () => import('views/brand/product/Index'),
          title: '团课产品管理',
          keepAlive: true,
          name: 'ProductIndex',
          permission: ['brand', 'products'],
          root: true,
          hidden: true,
        },
        {
          path: ':brandId/kid_products',
          component: () => import('views/brand/product/KidIndex'),
          title: '团课产品管理',
          keepAlive: true,
          name: 'KidProductIndex',
          permission: ['brand', 'products'],
          root: true,
          hidden: true,
        },
        {
          path: ':brandId/studios',
          component: () => import('views/brand/studio/List'),
          title: '门店管理',
          keepAlive: true,
          name: 'StudioIndex',
          permission: ['brand', 'studios'],
          root: true,
        },
        {
          path: ':brandId/studios/:studioId/detail',
          component: () => import('views/brand/studio/Detail'),
          name: 'studioDetail',
          title: '门店详情',
          root: true,
        },
        {
          path: ':brandId/studios/:studioId/pictures/add',
          component: () => import('views/brand/studio/pictures/Add'),
          name: 'studioAddPictures',
          title: '添加场地图片',
          root: true,
        },

      ]
    },
    {
      path: 'themes',
      component: () => import('views/brand/themes/Index'),
      title: '主题管理',
      keepAlive: true,
      name: 'ThemeIndex',
      permission: ['brand', 'themes'],
      hidden: false,
    },
    {
      path: 'roles',
      component: () => import('views/brand/roles/Index'),
      title: '品牌权限组管理',
      keepAlive: true,
      name: 'BrandRoleIndex',
      permission: ['brand', 'roles'],
      hidden: false,
    },
    {
      path: 'pay_configs',
      component: () => import('views/brand/pay_configs/Index'),
      title: '支付配置管理',
      keepAlive: true,
      name: 'PayConfigIndex',
      permission: ['brand', 'pay_configs'],
      hidden: false,
    }
  ],
}
