<template>
  <el-select 
    :value="id"
    placeholder="选择权限组"
    size='small'
    class="item"
    remote
    :remote-method="fetchData"
    :loading='isLoading'
    @change='handleChange'
  >
    <el-option
      v-for="item in sourceData"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>  
</template>

<script>
import { API } from '@/services/platform'
import fetch from '@/mixins/fetch'

export default {
  name: 'MfRoles',

  model: {
    prop: 'id',
    event: 'change',
  },
  
  props: {
    id: null,
  },
  
  data() {
    return {
      sourceData: null,
      isLoading: true,
    }
  },
  
  created() {
    this.fetchData()
  },
  
  methods: {
    async fetchData(query) {
      this.isLoading = true
      API.adminRoleList({id: this.id, query: query}).then(res => {
        this.sourceData = res
        this.isLoading = false
      }) 
    },

    handleChange(val) {
      this.$emit('change', val)
    },
  }
}
</script>

<style lang='scss' scoped>

</style>